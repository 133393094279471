import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="footer_category">Рошан</div>
      <div className="footer_category">Терзатель</div>
      <div className="footer_category">Руна мудрости</div>
      <div className="footer_category">Укрепление строений</div>
      <div className="footer_category">Руна воды</div>
      <div className="footer_category">Руна мудрости</div>
      <div className="footer_category">Выкуп</div>
    </footer>
  );
};

export default Footer;
