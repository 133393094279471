import "./App.css";
import Card from "./components/card/Card";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Modes_list from "./components/modes_list/Modes_list";
import Modal_window from "./components/modal_window/Modal_window";
import { useState } from "react";
import Basic_Btn from "./components/UI/basic_Btn/Basic_Btn";
import Delete_All_Btn from "./components/UI/delete_All_Btn/Delete_All_Btn";
import Roshan_guide from "./components/guide_pages/roshan/Roshan_guide";
import Lotus_guide from "./components/guide_pages/lotus/Lotus_guide";

function App() {
  const [active_timers, set_active_timers] = useState([]);

  function select_timer(timer) {
    set_active_timers((prevTimers) => [...prevTimers, timer]);
  }
  function delete_timer(id) {
    set_active_timers(active_timers.filter((obj) => obj.id != id));
  }

  return (
    <div className="App">
      <Header />
      {/* <Roshan_guide /> */}
      {/* <Lotus_guide /> */}

      <Modes_list select_timer={select_timer} />
      <div className="cards_container">
        {active_timers.length === 0 ? (
          <div>
            <Modal_window set_active_timers={set_active_timers} />
          </div>
        ) : (
          active_timers.map((item) => (
            <Card
              id={item.id}
              image={item.image}
              name={item.name}
              timer={item.timer}
              restart={item.restart}
              restartOn={item.restartOn}
              delete_timer={delete_timer}
              timerNotification={item.timerNotification}
              audioNotificaton={item.audioNotificaton}
              finishNotification={item.finishNotification}
            />
          ))
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
