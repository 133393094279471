// import React from "react";
// import "./modal_window.css";
// const Modal_window = () => {
//   return (
//     <div className="modal_massage">
//       <p>
//         Выберите желаемые события или нажмите на кнопку "Старт" для запуска{" "}
//       </p>
//       <button className="basic_modal_btn">Старт</button>
//     </div>
//   );
// };

// export default Modal_window;

import React from "react";
import "./modal_window.css";
const Modal_window = () => {
  return (
    <div className="modal_massage">
      <p> Сайт находится на стадии разработки</p>
      <p>
        Основной функционал работает исправно, но могут встречаться небольший
        баги, которые будут исправлены в ближайшее время{" "}
      </p>
    </div>
  );
};

export default Modal_window;
