import React, { useState } from "react";
import "./card.css";
import Timer from "../timer/Timer";

const Card = ({
  image,
  name,
  delete_timer,
  id,
  timer,
  restart,
  timerNotification,
  audioNotificaton,
  finishNotification,
}) => {
  const [isRunning, setIsRunning] = useState(false);
  const [isStartActive, setIsStartActive] = useState(false);
  const [isStopActive, setIsStopActive] = useState(false);
  const [isRestartOn, setIsRestartOn] = useState(false);

  const [initialTime, setInitialTime] = useState(timer);

  // console.log(initialTime);

  const handleStartClick = () => {
    setIsRunning(true);
    setIsStartActive(true);
    setIsStopActive(false);
  };

  const handleStopClick = () => {
    setIsRunning(false);
    setIsStartActive(false);
    setIsStopActive(true);
  };

  // Переключение тумблера 'рестарт таймера'
  const restartOnFn = () => {
    setIsRestartOn(!isRestartOn);
  };

  return (
    <div className="card_container">
      <button className="close_btn" onClick={() => delete_timer(id)}>
        X
      </button>
      <img className="event_image" src={image} alt="" />
      <p className="event_name">{name}</p>
      <div className="timer">
        <Timer
          initialMinutes={initialTime[0]}
          initialSeconds={initialTime[1]}
          timerNotification={timerNotification}
          isRunning={isRunning}
          restart={restart}
          restartOnFn={restartOnFn}
          isRestartOn={isRestartOn}
          audioNotificaton={audioNotificaton}
          finishNotification={finishNotification}
          handleStopClick={handleStopClick}
        />

        {restart ? (
          <label className="switch">
            <input onClick={restartOnFn} type="checkbox" />
            <span className="slider round"></span>
            <div className="switchText"> Обновлять таймер при завершении</div>
          </label>
        ) : (
          <div></div>
        )}
      </div>
      <div className="btn_in_card">
        <button
          className={`start_btn ${isStartActive ? "active" : ""}`}
          onClick={handleStartClick}
        >
          Старт
        </button>
        <button
          className={`stop_btn ${isStopActive ? "active" : ""}`}
          onClick={handleStopClick}
        >
          Сброс
        </button>
      </div>
    </div>
  );
};

export default Card;
