import React, { useEffect, useState } from "react";

const CountdownTimer = ({
  initialMinutes,
  initialSeconds,
  isRunning,
  timerNotification,
  audioNotificaton,
  finishNotification,
  restart,
  isRestartOn,
}) => {
  const totalSeconds = initialMinutes * 60 + initialSeconds;
  const [remainingTime, setRemainingTime] = useState(totalSeconds);
  const workerRef = React.useRef();

  // console.log(timerNotification[0] + timerNotification[1]);

  useEffect(() => {
    workerRef.current = new Worker(
      new URL("./timerWorker.js", import.meta.url)
    );

    workerRef.current.onmessage = (e) => {
      const { remainingTime, finished } = e.data;

      //       // Уведомление о тайминге
      //       if (
      //         // remainingTime > 0 &&
      //         currentMinutes === timerNotification[0] &&
      //         currentSeconds === timerNotification[1]
      //       ) {
      //         oneMinNotification();
      //       }
      //     }, 1000);
      // console.log("remainingTime", remainingTime);
      // console.log("timerNotification", timerNotification);

      // Аудиво уведомление о скором срабатывании таймера
      if (remainingTime === timerNotification) {
        const notificationAudio = new Audio(audioNotificaton);
        notificationAudio.play();
        console.log("notification");
      }

      if (finished) {
        // Воспроизводим звук в основном потоке
        const endAudio = new Audio(finishNotification);
        endAudio.play();
        console.log("finish");
      } else {
        setRemainingTime(remainingTime);
      }
    };

    return () => {
      workerRef.current.terminate();
    };
  }, [finishNotification]);

  useEffect(() => {
    // запуск таймера
    if (isRunning) {
      workerRef.current.postMessage({
        command: "start",
        data: { totalSeconds },
      });
    }
  }, [isRunning, totalSeconds]);

  // рестарт таймера
  if (remainingTime === 0 && restart && isRestartOn) {
    console.log("Рестарт таймера");

    workerRef.current.postMessage({
      command: "reset",
      data: { totalSeconds },
    });
  }

  // const resetTimer = () => {
  //   if (remainingTime <= 1 && restart && isRestartOn) {
  //     console.log("Рестарт таймера");

  //     workerRef.current.postMessage({
  //       command: "reset",
  //       data: { totalSeconds },
  //     });
  //   }
  // };

  useEffect(() => {
    // resetTimer();
  }, [restart, isRestartOn]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div>
      <p>
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
      </p>
    </div>
  );
};

export default CountdownTimer;

// import React, { useEffect, useState } from "react";

// const CountdownTimer = ({
//   initialMinutes,
//   initialSeconds,
//   isRunning,
//   timerNotification,
//   audioNotificaton,
//   finishNotification,
//   restart,
//   isRestartOn,
// }) => {
//   const totalSeconds = initialMinutes * 60 + initialSeconds;
//   const [remainingTime, setRemainingTime] = useState(totalSeconds);
//   const workerRef = React.useRef();

//   useEffect(() => {
//     workerRef.current = new Worker(
//       new URL("./timerWorker.js", import.meta.url)
//     );

//     workerRef.current.onmessage = (e) => {
//       const { remainingTime, finished } = e.data;
//       if (finished) {
//         // notification for finish
//         console.log("finish");
//       } else {
//         setRemainingTime(remainingTime);
//       }
//     };

//     return () => {
//       workerRef.current.terminate();
//     };
//   }, []);

//   useEffect(() => {
//     if (isRunning) {
//       workerRef.current.postMessage({
//         command: "start",
//         data: { totalSeconds, audioNotificaton, finishNotification },
//       });
//     }
//   }, [isRunning]);

//   const resetTimer = () => {
//     if (restart && isRestartOn) {
//       workerRef.current.postMessage({
//         command: "reset",
//         data: { totalSeconds },
//       });
//     }
//   };

//   useEffect(() => {
//     resetTimer();
//   }, [restart, isRestartOn]);

//   const minutes = Math.floor(remainingTime / 60);
//   const seconds = remainingTime % 60;

//   return (
//     <div>
//       <p>
//         {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
//       </p>
//     </div>
//   );
// };

// export default CountdownTimer;

// ----------------------------------------------------------

// import React, { useEffect, useState } from "react";

// const CountdownTimer = ({
//   initialMinutes,
//   initialSeconds,
//   isRunning,
//   timerNotification,
//   audioNotificaton,
//   finishNotification,
//   restart,
//   isRestartOn,
// }) => {
//   const totalSeconds = initialMinutes * 60 + initialSeconds;
//   const [endTime, setEndTime] = useState(null);
//   const [remainingTime, setRemainingTime] = useState(totalSeconds); // Состояние оставшегося времени

//   const resetTimer = () => {
//     console.log("Функция resetTimer сработала");
//     setEndTime(Date.now() + totalSeconds * 1000);
//     setRemainingTime(totalSeconds);
//     console.log("total seconds: ", totalSeconds);
//     console.log("remainingTime: ", remainingTime);
//   };

//   useEffect(() => {
//     if (restart && isRestartOn) {
//       // resetTimer(); // Сброс времени при перезапуске
//     }
//   }, [restart, isRestartOn]);

//   useEffect(() => {
//     if (isRunning) {
//       resetTimer(); // Установить время при запуске
//     }
//   }, [isRunning]);

//   // Уведомление об одной минуте на таймере
//   const oneMinNotification = () => {
//     const oneMin = new Audio(audioNotificaton);
//     console.log("1 min");
//     oneMin.play();
//   };

//   // Уведомление об окончании таймера
//   const finishTimerNotification = () => {
//     const end = new Audio(finishNotification);
//     end.play();
//   };

//   useEffect(() => {
//     if (!isRunning || remainingTime <= 0) return;

//     const intervalId = setInterval(() => {
//       setRemainingTime((prevTime) =>
//         Math.max(0, Math.floor((endTime - Date.now()) / 1000))
//       );

//       // Останавливает таймер либо обновляет его

//       console.log(remainingTime);
//       console.log("isRestartOn ", isRestartOn);
//       console.log("restart ", restart);

//       if (remainingTime <= 2 && isRestartOn === true && restart === true) {
//         console.log("restart");
//         finishTimerNotification();
//         resetTimer();
//         console.log("restart был произведен");
//       }
//       if (remainingTime <= 1 && isRestartOn === false) {
//         finishTimerNotification();
//         clearInterval(intervalId); // Остановить таймер
//         console.log("таймер был остановлен здесь");
//       }

//       // if (remainingTime <= 1 && restart && isRestartOn) {
//       //   console.log("restart");
//       //   finishTimerNotification();
//       //   resetTimer();
//       // }

//       const currentMinutes = Math.floor(remainingTime / 60);
//       const currentSeconds = remainingTime % 60;

//       // Уведомление о тайминге
//       if (
//         // remainingTime > 0 &&
//         currentMinutes === timerNotification[0] &&
//         currentSeconds === timerNotification[1]
//       ) {
//         oneMinNotification();
//       }
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, [isRunning, endTime, remainingTime, timerNotification]);

//   const minutes = Math.floor(remainingTime / 60);
//   const seconds = remainingTime % 60;

//   return (
//     <div>
//       <p>
//         {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
//       </p>
//     </div>
//   );
// };

// export default CountdownTimer;
