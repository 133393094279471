import React from "react";
import "./modes_list.css";
import Timer from "../timer/Timer";

import roshan from "../card/images/roshan_img.png";
import roshan_orig from "../card/images/roshan_icon.png";
import roshan_1_min from "../audio/roshan/roshan_1_min.mp4";
import roshan_ready from "../audio/roshan/roshan_ready.mp4";

import lotus2 from "../card/images/lotus_icon2.png";
import lotus_orig from "../card/images/lotus_orig.png";
import lotus_1_min from "../audio/lotus/lotus_1_min.mp4";
import lotus_ready from "../audio/lotus/lotus_ready.mp4";

import water_rune from "../card/images/water_rune.png";
import water_rune_orig from "../card/images/water_rune_orig.png";
import water_rune_1_min from "../audio/water_rune/water_rune_20_sec.mp4";
import water_rune_ready from "../audio/water_rune/water_rune_ready.mp4";

import buyBack from "../card/images/buyBack_no_background.png";
import buyBack_orig from "../card/images/golden_icon.png";
import buyBack_1_min from "../audio/buyBack/buyBack_1_min.mp4";
import buyBack_ready from "../audio/buyBack/buyBack_ready.mp4";

import glyth from "../card/images/glyth.png";
import glyth_orig from "../card/images/glyth_orig2.png";
import glyph_1_min from "../audio/glyph/glyph_1_min.mp4";
import glyph_ready from "../audio/glyph/glyph_ready.mp4";

import wisdom_rune from "../card/images/wisdom_rune.png";
import wisdom_orig from "../card/images/wisdom_orig.png";
import wisdom_rune_1_min from "../audio/wisdom_rune/wisdom_rune_1_min.mp4";
import wisdom_rune_ready from "../audio/wisdom_rune/wisdom_rune_ready.mp4";

import tormentor_radiant from "../card/images/tormentor_radiant.png";
import tormentor_radiant_1_min from "../audio/tormentor_radiant/tormentor_radiant_1_min.mp4";
import tormentor_radiant_ready from "../audio/tormentor_radiant/tormentor_radiant_ready.mp4";

import tormentor_dire from "../card/images/tormentor_dire.png";
import tormentor_dire_1_min from "../audio/tormentor_dire/tormentor_dire_1_min.mp4";
import tormentor_dire_ready from "../audio/tormentor_dire/tormentor_dire_ready.mp4";

const Modes_list = ({ select_timer }) => {
  return (
    <div className="wrapper">
      <div className="modes_list_container">
        <button
          onClick={() =>
            // Свойства таймера
            select_timer({
              id: 1, // Уникальный id
              image: roshan, // Картинка
              name: "Рошан", // Название эвента
              timer: [8, 0], // Таймер
              restart: false, // Обновлять таймер при завершении
              timerNotification: 60, // Тайминг уведомления
              audioNotificaton: roshan_1_min, // Звуковая дорожка уведомления
              finishNotification: roshan_ready, // Звуковая дорожка окончания таймера
            })
          }
          className="modes_list_btn"
        >
          <img className="modes_list_icons" src={roshan_orig} alt="" />
        </button>
        <button
          onClick={() =>
            select_timer({
              id: 2,
              image: lotus2,
              name: "Лотус",
              timer: [3, 0],
              restart: true,
              restartOn: false,
              timerNotification: 60,
              audioNotificaton: lotus_1_min,
              finishNotification: lotus_ready,
            })
          }
          className="modes_list_btn"
        >
          <img className="modes_list_icons" src={lotus_orig} alt="" />
        </button>

        <button
          onClick={() =>
            select_timer({
              id: 3,
              image: water_rune,
              name: "Водная руна",
              timer: [2, 0],
              restart: true,
              timerNotification: 20,
              audioNotificaton: water_rune_1_min,
              finishNotification: water_rune_ready,
            })
          }
          className="modes_list_btn"
        >
          <img className="modes_list_icons" src={water_rune_orig} alt="" />
        </button>
        <button
          onClick={() =>
            select_timer({
              id: 4,
              image: wisdom_rune,
              name: "Руна мудрости",
              timer: [7, 0],
              restart: true,
              timerNotification: 60,
              audioNotificaton: wisdom_rune_1_min,
              finishNotification: wisdom_rune_ready,
            })
          }
          className="modes_list_btn"
        >
          <img className="modes_list_icons" src={wisdom_orig} alt="" />
        </button>
        <button
          onClick={() =>
            select_timer({
              id: 5,
              image: buyBack,
              name: "Выкуп",
              timer: [8, 0],
              restart: false,
              timerNotification: 60,
              audioNotificaton: buyBack_1_min,
              finishNotification: buyBack_ready,
            })
          }
          className="modes_list_btn"
        >
          <img className="modes_list_icons" src={buyBack_orig} alt="" />
        </button>
        <button
          onClick={() => {
            select_timer({
              id: 6,
              image: glyth,
              name: "Глиф",
              timer: [5, 0],
              restart: false,
              timerNotification: 60,
              audioNotificaton: glyph_1_min,
              finishNotification: glyph_ready,
            });
          }}
          className="modes_list_btn"
        >
          {" "}
          <img className="modes_list_icons" src={glyth_orig} alt="" />
        </button>
        <button
          onClick={() => {
            select_timer({
              id: 7,
              image: tormentor_radiant,
              name: "Терзатель свет",
              timer: [10, 0],
              restart: false,
              timerNotification: 60,
              audioNotificaton: tormentor_radiant_1_min,
              finishNotification: tormentor_radiant_ready,
            });
          }}
          className="modes_list_btn"
        >
          {" "}
          <img className="modes_list_icons" src={tormentor_radiant} alt="" />
        </button>
        <button
          onClick={() => {
            select_timer({
              id: 8,
              image: tormentor_dire,
              name: "Терзатель тьма",
              timer: [10, 0],
              restart: false,
              timerNotification: 60,
              audioNotificaton: tormentor_dire_1_min,
              finishNotification: tormentor_dire_ready,
            });
          }}
          className="modes_list_btn"
        >
          {" "}
          <img className="modes_list_icons" src={tormentor_dire} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Modes_list;
