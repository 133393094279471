import React from "react";
import "./header.css";
import logo from "../header/logo2.png";
const Header = () => {
  return (
    <nav>
      {/* <img src="/logo.svg" alt="Логотип" className="logo" /> */}
      {/* <img src="/logo2.png" alt="Логотип" className="logo" /> */}
      <div className="header_wrapper">
        <img src={logo} alt="" className="logo" />
        <p className="header_name">Dota2Timer</p>
      </div>

      {/* <header className="header_name">Таймеры событий Dota 2</header> */}
    </nav>
  );
};

export default Header;
